import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Button, Paper, Box, CircularProgress, Container, ThemeProvider, createTheme, Snackbar } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.js';
import ShareIcon from '@mui/icons-material/Share';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GaugeMeter from './GaugeMeter';
import { calculateScore, getScoreColor, getScoreExplanation } from '../utils/ScoringLogic';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#facc15',
    },
    background: {
      default: '#000000',
      paper: '#1a0033',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function IdeaSummary() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [idea, setIdea] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [aiAnalysis, setAiAnalysis] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchAnalysis = useCallback(async (ideaData) => {
    try {
      console.log("Fetching analysis for idea:", ideaData.id);

          // Generate the idea description
      const description = getIdeaDescription(ideaData);

    // Construct the payload with the description and scoring metrics
      const essentialData = {
        description, // Include the generated description
        templateId: ideaData.templateId,
        templateData: ideaData.templateData,
        importanceOfProblem: ideaData.importanceOfProblem,
        frequencyOfProblem: ideaData.frequencyOfProblem,
        targetAudienceDefinition: ideaData.targetAudienceDefinition,
        satisfactionWithAlternatives: ideaData.satisfactionWithAlternatives,
        competitiveDifferentiation: ideaData.competitiveDifferentiation,
        marketSizeAndGrowth: ideaData.marketSizeAndGrowth,
        executionAbility: ideaData.executionAbility,
        customerAcquisitionPlan: ideaData.customerAcquisitionPlan,
        marketReadiness: ideaData.marketReadiness,
        revenueModel: ideaData.revenueModel,
        scalableRevenue: ideaData.scalableRevenue,
        costsAndProfitMargins: ideaData.costsAndProfitMargins,
        score: calculateScore(ideaData),
      };
      
      console.log("Payload being sent:", JSON.stringify(essentialData));
      console.log("Sending request to /api/generateAIExplanation");
      const response = await fetch('/api/generateAIExplanation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idea: essentialData })
      });
  
      console.log("Response status:", response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Received data:", data);
      if (data.error) {
        throw new Error(data.error);
      }
  
      const analysis = data.explanation;
      console.log("Setting AI analysis:", analysis);
      setAiAnalysis(analysis);
  
      console.log("Updating Firestore document");
      const ideaRef = doc(db, 'ideas', ideaData.id);
      await updateDoc(ideaRef, { aiAnalysis: analysis });
  
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setAiAnalysis('Error fetching AI analysis. Please try again later.');
    }
  }, []);

  useEffect(() => {
    const fetchIdea = async () => {
      try {
        const docRef = doc(db, 'ideas', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const ideaData = { id: docSnap.id, ...docSnap.data() };
          setIdea(ideaData);
          if (ideaData.aiAnalysis) {
            setAiAnalysis(ideaData.aiAnalysis);
          } else {
            await fetchAnalysis(ideaData);
          }
        } else {
          setError('Idea not found');
        }
      } catch (error) {
        console.error('Error fetching idea:', error);
        setError('Error fetching idea');
      } finally {
        setIsLoading(false);
      }
    };

    fetchIdea();
  }, [id, fetchAnalysis]);

  const handleEdit = () => {
    navigate(`/ideaform/${id}`);
  };

  const handleShare = useCallback(() => {
    const shareUrl = `${window.location.origin}/shared/${id}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      setSnackbarOpen(true);
    });
  }, [id]);

  const getIdeaDescription = (idea) => {
    switch (idea.templateId) {
      case 'classic':
        return `${idea.templateData['Product Name'] || 'Product'} ${idea.templateData['helps'] || 'helps'} ${idea.templateData['Target Audience'] || 'target audience'} ${idea.templateData['achieve'] || 'achieve'} ${idea.templateData['Desired Goal'] || 'desired goal'} ${idea.templateData['by'] || 'by'} ${idea.templateData['Product Use Case'] || 'product use case'}.`;
      case 'problem':
        return `${idea.templateData['A Problem'] || 'A problem'} ${idea.templateData['for'] || 'for'} ${idea.templateData['Well Defined Target Customers'] || 'target customers'} ${idea.templateData['because'] || 'because'} ${idea.templateData['Specific Underlying Cause of the Problem'] || 'cause'} ${idea.templateData['whichLeadsTo'] || 'which leads to'} ${idea.templateData['The Problem Impact'] || 'impact'}.`;
      case 'solution':
        return `${idea.templateData['byOffering'] || 'By offering'} ${idea.templateData['Key Solution'] || 'solution'}, ${idea.templateData['Product/Service'] || 'product/service'} ${idea.templateData['helps'] || 'helps'} ${idea.templateData['Target Audience'] || 'target audience'} ${idea.templateData['overcome'] || 'overcome'} ${idea.templateData['Specific Problem'] || 'problem'}.`;
      case 'value':
        return `${idea.templateData['Product/Service'] || 'Product/Service'} ${idea.templateData['simplifies'] || 'simplifies'} ${idea.templateData['Process/Task'] || 'process/task'} for ${idea.templateData['Target Audience'] || 'target audience'} ${idea.templateData['by'] || 'by'} ${idea.templateData['How'] || 'how'}, ${idea.templateData['leadingTo'] || 'leading to'} ${idea.templateData['Benefit'] || 'benefit'}.`;
      default:
        return 'No description available.';
    }
  };

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Typography color="error">{error}</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  if (!idea) {
    return null;
  }

  const score = calculateScore(idea);
  const scoreColor = getScoreColor(score);
  const scoreExplanation = getScoreExplanation(score);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4, backgroundColor: 'background.paper' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4" component="h1" gutterBottom color="primary">
              <EmojiObjectsIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              {idea.templateData['Product Name'] || idea.templateData['Product/Service'] || 'Untitled'}
            </Typography>
            <Box>
              <Button startIcon={<ShareIcon />} onClick={handleShare} sx={{ mr: 1 }}>
                Share
              </Button>
              <Button variant="contained" onClick={handleEdit}>
                Edit
              </Button>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <AutoAwesomeIcon sx={{ color: scoreColor, mr: 1 }} />
            <Typography variant="h4" component="span" sx={{ color: scoreColor, fontSize: '2rem' }}>
              Score: {score.toFixed(2)}
            </Typography>
          </Box>
  
          <GaugeMeter score={score} />
          
          <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>{scoreExplanation}</Typography>
          
          <Typography variant="h6" gutterBottom color="primary">
            Idea Description
          </Typography>

          <Typography variant="body1" paragraph>
            {getIdeaDescription(idea)}
          </Typography>

          <Typography variant="h6" gutterBottom color="primary">
            IdeallyAI Analysis
          </Typography>
          <Typography variant="body1" paragraph>{aiAnalysis}</Typography>

          <Typography variant="h6" gutterBottom color="primary">
            Goal
          </Typography>
          <Typography variant="body1" paragraph>{idea.goal || 'Not specified'}</Typography>
          <Typography variant="h6" gutterBottom color="primary" sx={{ mt: 4 }}>
            Detailed Scores
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            {[
              { label: 'Importance of Problem', value: idea.importanceOfProblem },
              { label: 'Frequency of Problem', value: idea.frequencyOfProblem },
              { label: 'Target Audience Definition', value: idea.targetAudienceDefinition },
              { label: 'Satisfaction with Alternatives', value: idea.satisfactionWithAlternatives },
              { label: 'Competitive Differentiation', value: idea.competitiveDifferentiation },
              { label: 'Market Size and Growth', value: idea.marketSizeAndGrowth },
              { label: 'Execution Ability', value: idea.executionAbility },
              { label: 'Customer Acquisition Plan', value: idea.customerAcquisitionPlan },
              { label: 'Market Readiness', value: idea.marketReadiness },
              { label: 'Revenue Model', value: idea.revenueModel },
              { label: 'Scalable Revenue', value: idea.scalableRevenue },
              { label: 'Costs and Profit Margins', value: idea.costsAndProfitMargins },
            ].map((item, index) => (
              <Box key={index} width="30%" mb={2}>
                <Typography variant="body2">{item.label}</Typography>
                <Typography variant="h6" color="primary">
                  {item.label === 'Revenue Model' ? item.value : `${item.value}/5`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Share link copied to clipboard!"
      />
    </ThemeProvider>
  );
}

export default IdeaSummary;