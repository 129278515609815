import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Button, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent,
  ThemeProvider, 
  createTheme,
  CssBaseline
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BoltIcon from '@mui/icons-material/Bolt';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#facc15',
    },
    background: {
      default: '#000000',
      paper: '#1a0033',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const LandingPage = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: 'linear-gradient(to bottom, #000000, #4a0072)',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ width: '100%', px: 3, py: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AutoAwesomeIcon sx={{ fontSize: 40, color: '#facc15' }} />
            <Typography variant="h4" component="span" fontWeight="bold">
              Ideally
            </Typography>
          </Box>
          <Button 
            variant="outlined" 
            color="primary"
            onClick={() => navigate('/login')}
          >
            LOG IN
          </Button>
        </Box>

        <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', px: 3, py: { xs: 4, sm: 6, md: 8 } }}>
          <Typography variant="h2" component="h1" gutterBottom fontWeight="bold" sx={{ maxWidth: '100%' }}>
            Don't Waste Time on Bad Ideas
          </Typography>
          <Typography variant="h5" paragraph sx={{ maxWidth: '100%', mb: 4 }}>
            Save time and money by ensuring your next big idea is worth pursuing. Ideally helps you prioritize what matters most. built on years of product expertise.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleGetStarted}
            sx={{
              backgroundColor: '#facc15',
              color: 'black',
              '&:hover': {
                backgroundColor: '#fbbf24',
              },
              px: 4,
              py: 1.5,
              fontSize: '1.1rem'
            }}
          >
            GET STARTED
          </Button>
        </Box>

        <Box sx={{ width: '100%', px: 3, py: { xs: 4, sm: 6, md: 8 } }}>
          <Typography variant="h3" align="center" gutterBottom fontWeight="bold">
            Focus on Opportunties
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <FeatureCard 
              icon={<LightbulbIcon sx={{ fontSize: 48, color: '#facc15' }} />}
              title="Expert-Driven Insights"
              description="Leverage years of product expertise combined with AI to gain actionable insights and identify opportunities."
            />
            <FeatureCard 
              icon={<TrendingUpIcon sx={{ fontSize: 48, color: '#facc15' }} />}
              title="Opportunity Scoring"
              description="Quantify the potential of your ideas with our trusted scoring method, so you always invest in the right project."
            />
            <FeatureCard 
              icon={<BoltIcon sx={{ fontSize: 48, color: '#facc15' }} />}
              title="Idea Validation"
              description="Our unique methodology, refined over years of research, provides a comprehensive insights so you can validate your ideas with more confidence."
            />
          </Grid>
        </Box>

        <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', px: 3, py: { xs: 4, sm: 6, md: 8 } }}>
          <Typography variant="h3" gutterBottom fontWeight="bold" sx={{ maxWidth: '100%' }}>
            Ready to Validate Your Ideas?
          </Typography>
          <Typography variant="h6" paragraph sx={{ maxWidth: '100%', mb: 4 }}>
            Join 1,500+ innovative thinkers using Ideally to turn concepts into successful ventures.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleGetStarted}
            sx={{
              backgroundColor: '#facc15',
              color: 'black',
              '&:hover': {
                backgroundColor: '#fbbf24',
              },
              px: 4,
              py: 1.5,
              fontSize: '1.1rem'
            }}
          >
            GET STARTED
          </Button>
        </Box>

        <Box component="footer" sx={{ width: '100%', py: 3, textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.1)' }}>
          <Typography variant="body2">
            Made with ❤️ by{' '}
            <a 
              href="https://il.linkedin.com/in/revivolior" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ color: '#facc15', fontWeight: 'bold', textDecoration: 'none' }}
            >
              Lior Revivo
            </a>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Grid item xs={12} md={4}>
      <Card 
        sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(255,255,255,0.05)',
          backdropFilter: 'blur(10px)',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)',
          }
        }}
      >
        <CardContent>
          <Box sx={{ mb: 2 }}>{icon}</Box>
          <Typography variant="h5" component="h3" gutterBottom fontWeight="bold" color="white">
            {title}
          </Typography>
          <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.8)' }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default LandingPage;
