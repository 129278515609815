import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Select, MenuItem, InputBase, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

const templates = [
  {
    id: 'solution',
    name: 'Solution-focused',
    text: '{byOffering} [Key Solution], [Product/Service] {helps} [Target Audience] {overcome} [Specific Problem].',
    customizableWords: ['byOffering', 'helps', 'overcome'],
    options: {
      byOffering: ['By offering', 'By delivering', 'With', 'Utilizing', 'Offering', 'Through', 'By introducing'],
      helps: ['helps', 'allows', 'enables', 'empowers', 'assists', 'supports', 'makes it easier', 'saves', 'increases'],
      Save: ['save', 'reduce', 'increase', 'avoid', 'overcome', 'solve', 'address', 'tackle', 'eliminate', 'manage', 'resolve', 'mitigate'],
    },
  },
  {
    id: 'goal',
    name: 'Goal-focused',
    text: '[Product Name] {helps} [Target Audience] {achieve} [Desired Goal] {by} [Product Use Case].',
    customizableWords: ['helps', 'achieve', 'by'],
    options: {
      helps: ['helps', 'allows', 'enables', 'empowers', 'assists', 'supports', 'facilitates', 'provides'],
      Reduce: ['achieve', 'attain', 'reach', 'obtain', 'accomplish', 'save', 'experience', 'reduce', 'increase'],
      by: ['by', 'through', 'via', 'using', 'with', 'employing', 'leveraging'],
    },
  },
  {
    id: 'value',
    name: 'Value-focused',
    text: '[Product/Service] {simplifies} [Process/Task] for [Target Audience] {by} [How], {leadingTo} [Benefit].',
    customizableWords: ['simplifies', 'by', 'leadingTo'],
    options: {
      simplifies: ['saves', 'reduces', 'increases', 'simplifies', 'streamlines', 'enhances', 'optimizes', 'automates', 'improves', 'facilitates', 'makes it easier'],
      by: ['by', 'through', 'via', 'using', 'with', 'employing', 'leveraging'],
      leadingTo: ['leading to', 'resulting in', 'causing', 'bringing about', 'contributing to'],
    },
  },
];

function DynamicWidthInput({ placeholder, value, onChange, disabled }) {
  const inputRef = useRef(null);
  const [width, setWidth] = useState('auto');

  useEffect(() => {
    if (inputRef.current) {
      const placeholderWidth = getTextWidth(placeholder, getComputedStyle(inputRef.current).font);
      const valueWidth = getTextWidth(value, getComputedStyle(inputRef.current).font);
      const newWidth = Math.max(placeholderWidth, valueWidth, 100) + 20; // Add some padding
      setWidth(`${newWidth}px`);
    }
  }, [value, placeholder]);

  function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  }

  return (
    <InputBase
      inputRef={inputRef}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      sx={{
        mx: 1,
        display: 'inline-block',
        width: width,
        '& input': {
          borderBottom: '1px solid #facc15',
          color: '#fff',
          padding: '2px 4px',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)',
            opacity: 1,
          },
        },
        '& input:focus': {
          borderBottomWidth: '2px',
        },
      }}
    />
  );
}

function IdeaDescription({ templateId, templateData, onChange }) {
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [localTemplateData, setLocalTemplateData] = useState(templateData);
  const [customInputs, setCustomInputs] = useState({});
  const [editingCustom, setEditingCustom] = useState(null);

  useEffect(() => {
    const index = templates.findIndex(t => t.id === templateId);
    setSelectedTemplate(index >= 0 ? index : 0);
    setLocalTemplateData(templateData);
  }, [templateId, templateData]);

  const handleTemplateChange = (direction) => {
    if (editMode) return;
    const newIndex = direction === 'next'
      ? (selectedTemplate + 1) % templates.length
      : (selectedTemplate - 1 + templates.length) % templates.length;
    setSelectedTemplate(newIndex);
    onChange(templates[newIndex].id, {});
  };

  const handleCustomWordChange = (word, value) => {
    if (value === 'custom') {
      setEditingCustom(word);
    } else {
      const newTemplateData = { ...localTemplateData, [word]: value };
      setLocalTemplateData(newTemplateData);
      onChange(templates[selectedTemplate].id, newTemplateData);
    }
  };

  const handleCustomInputChange = (word, value) => {
    setCustomInputs({ ...customInputs, [word]: value });
  };

  const saveCustomInput = (word) => {
    const newTemplateData = { ...localTemplateData, [word]: customInputs[word] };
    setLocalTemplateData(newTemplateData);
    onChange(templates[selectedTemplate].id, newTemplateData);
    setEditingCustom(null);
  };

  const handleInputChange = (placeholder, value) => {
    if (editMode) return;
    const newTemplateData = { ...localTemplateData, [placeholder]: value };
    setLocalTemplateData(newTemplateData);
    onChange(templates[selectedTemplate].id, newTemplateData);
  };

  const renderTemplate = () => {
    const template = templates[selectedTemplate];
    if (!template) return null;

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {template.text.split(/[\[\]{}]/).map((part, index) => {
          if (index % 2 === 0) {
            return <span key={index}>{part}</span>;
          } else {
            const isCustomizable = template.customizableWords.includes(part);
            if (isCustomizable && editMode) {
              const options = template.options[part] || [];
              const defaultValue = options.length > 0 ? options[0] : 'custom';
              const isCustom = editingCustom === part;

              return (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    value={localTemplateData[part] || defaultValue}
                    onChange={(e) => handleCustomWordChange(part, e.target.value)}
                    sx={{ minWidth: 120, mx: 1 }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                    <MenuItem value="custom">Add your own</MenuItem>
                  </Select>
                  {isCustom && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InputBase
                        value={customInputs[part] || ''}
                        onChange={(e) => handleCustomInputChange(part, e.target.value)}
                        placeholder="Enter your text"
                        sx={{
                          mx: 1,
                          width: '150px',
                          '& input': {
                            borderBottom: '1px solid #facc15',
                            color: '#fff',
                            padding: '2px 4px',
                            '&::placeholder': {
                              color: 'rgba(255, 255, 255, 0.5)',
                              opacity: 1,
                            },
                          },
                          '& input:focus': {
                            borderBottomWidth: '2px',
                          },
                        }}
                      />
                      <IconButton onClick={() => saveCustomInput(part)} size="small">
                        <CheckIcon sx={{ color: '#facc15' }} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              );
            } else if (isCustomizable) {
              const options = template.options[part] || [];
              const defaultValue = options.length > 0 ? options[0] : '';

              return (
                <Typography 
                  key={index} 
                  component="span"
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#facc15',
                    mx: 1,
                    whiteSpace: 'nowrap'
                  }}
                >
                  {localTemplateData[part] || defaultValue}
                </Typography>
              );
            } else {
              return (
                <DynamicWidthInput
                  key={index}
                  placeholder={part}
                  value={localTemplateData[part] || ''}
                  onChange={(e) => handleInputChange(part, e.target.value)}
                  disabled={editMode}
                />
              );
            }
          }
        })}
      </Box>
    );
  };

  if (selectedTemplate < 0 || selectedTemplate >= templates.length) {
    return <Typography>Invalid template selected</Typography>;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="body1" gutterBottom sx={{ fontSize: '1rem', mb: 2 }}>
        Complete the following sentence to describe your idea:
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 'medium' }}>
          Template: {templates[selectedTemplate].name}
        </Typography>
        <Box>
          <IconButton onClick={() => handleTemplateChange('prev')} disabled={editMode} size="small">
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => handleTemplateChange('next')} disabled={editMode} size="small">
            <ChevronRightIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => setEditMode(!editMode)} size="small">
            <EditIcon sx={{ fontSize: '1rem' }} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ mt: 2, mb: 4, lineHeight: 2, fontSize: '0.9rem', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        {renderTemplate()}
      </Box>
    </Box>
  );
}

export default IdeaDescription;