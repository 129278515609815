import React, { useState, useEffect } from 'react';
import { TextField, Button, Slider, Typography, Snackbar, Stepper, Step, StepLabel, Paper, ThemeProvider, createTheme, Container, Box, FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';
import { Alert } from '@mui/material';
import { collection, doc, setDoc, addDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase.js';
import { useNavigate, useParams } from 'react-router-dom';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import IdeaDescription from './IdeaDescription';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#facc15',
    },
    background: {
      default: '#000000',
      paper: '#1a0033',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function IdeaForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [idea, setIdea] = useState({
    goal: '',
    templateId: 'classic',
    templateData: {},
    importanceOfProblem: 3,
    frequencyOfProblem: 3,
    targetAudienceDefinition: 3,
    satisfactionWithAlternatives: 3,
    competitiveDifferentiation: 3,
    marketSizeAndGrowth: 3,
    executionAbility: 3,
    customerAcquisitionPlan: 3,
    marketReadiness: 3,
    revenueModel: '',
    scalableRevenue: 3,
    costsAndProfitMargins: 3,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isEditing, setIsEditing] = useState(false);

  const ideallyAITooltipText = "Coming Soon! Validate with IdeallyAI allows you to get market insights coupled with the expertise of IdeallyAI models to answer the most difficult business questions.";

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      const fetchIdea = async () => {
        const docRef = doc(db, 'ideas', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.templateId === 'quick note') {
            // Reset form for quick note
            setIdea({
              goal: '',
              templateId: 'classic',
              templateData: {},
              importanceOfProblem: 3,
              frequencyOfProblem: 3,
              targetAudienceDefinition: 3,
              satisfactionWithAlternatives: 3,
              competitiveDifferentiation: 3,
              marketSizeAndGrowth: 3,
              executionAbility: 3,
              customerAcquisitionPlan: 3,
              marketReadiness: 3,
              revenueModel: '',
              scalableRevenue: 3,
              costsAndProfitMargins: 3,
            });
          } else {
            setIdea({ ...data, id: docSnap.id });
          }
        } else {
          setSnackbar({ open: true, message: 'Idea not found', severity: 'error' });
        }
      };
      fetchIdea();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIdea(prevIdea => ({ ...prevIdea, [name]: value }));
  };

  const handleSliderChange = (name) => (event, newValue) => {
    setIdea(prevIdea => ({ ...prevIdea, [name]: newValue }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user) {
      setSnackbar({ open: true, message: 'You must be logged in to submit an idea', severity: 'error' });
      return;
    }

    try {
      const ideaData = {
        ...idea,
        userId: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      if (isEditing) {
        await setDoc(doc(db, 'ideas', id), ideaData);
        setSnackbar({ open: true, message: 'Idea updated successfully!', severity: 'success' });
      } else {
        const docRef = await addDoc(collection(db, 'ideas'), ideaData);
        setSnackbar({ open: true, message: 'Idea submitted successfully!', severity: 'success' });
        navigate(`/idea/${docRef.id}`);
      }
    } catch (error) {
      console.error("Error submitting idea: ", error);
      setSnackbar({ open: true, message: 'Error submitting idea. Please try again.', severity: 'error' });
    }
  };

  const handleDontKnow = (field) => {
    setIdea({ ...idea, [field]: 2 });
  };

  const handleValidateWithAI = async (field) => {
    // Implement AI validation logic here
    // This might involve calling an API endpoint
    // For now, we'll just set a placeholder value
    setIdea({ ...idea, [field]: 3 });
  };

  const steps = ['Goal and Idea Description', 'Value Proposition', 'Market Fit Validation', 'Execution & Go-To-Market', 'Business Model Analysis'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 4 }}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Goal</InputLabel>
              <Select
                name="goal"
                value={idea.goal}
                onChange={handleChange}
                required
              >
                <MenuItem value="Unicorn">Unicorn</MenuItem>
                <MenuItem value="Micro-SaaS">Micro-SaaS</MenuItem>
                <MenuItem value="Lifestyle Business">Lifestyle Business</MenuItem>
                <MenuItem value="Regular Business">Regular Business</MenuItem>
                <MenuItem value="Writing for the Drawer">Writing for the Drawer</MenuItem>
              </Select>
            </FormControl>
            <IdeaDescription
              templateId={idea.templateId}
              templateData={idea.templateData}
              onChange={(newTemplateId, newTemplateData) => {
                setIdea(prevIdea => ({
                  ...prevIdea,
                  templateId: newTemplateId,
                  templateData: newTemplateData
                }));
              }}
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Importance of the Problem</Typography>
            <Typography variant="body2">How important is the problem you're solving for your target audience?</Typography>
            <Slider
              name="importanceOfProblem"
              value={idea.importanceOfProblem}
              onChange={handleSliderChange('importanceOfProblem')}
              min={1}
              max={5}
              step={1}
              marks={false}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                const mark = [
                  'Not important',
                  'Low importance',
                  'Moderately important',
                  'Important',
                  'Critical problem'
                ][value - 1];
                return mark;
              }}
            />
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Target Audience Definition</Typography>
              <Typography variant="body2">How clearly can you define and reach your target audience?</Typography>
              <Slider
                name="targetAudienceDefinition"
                value={idea.targetAudienceDefinition}
                onChange={handleSliderChange('targetAudienceDefinition')}
                min={1}
                max={5}
                step={1}
                marks={false}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  const mark = [
                    'Very unclear',
                    'Somewhat unclear',
                    'Moderately clear',
                    'Mostly clear',
                    'Extremely clear'
                  ][value - 1];
                  return mark;
                }}
              />
            </Box>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Frequency of Need</Typography>
              <Typography variant="body2">How frequently does this problem occur for your target audience?</Typography>
              <Slider
                name="frequencyOfProblem"
                value={idea.frequencyOfProblem}
                onChange={handleSliderChange('frequencyOfProblem')}
                min={1}
                max={5}
                step={1}
                marks={false}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  const mark = [
                    'Yearly',
                    'Quarterly',
                    'Monthly',
                    'Weekly',
                    'Daily'
                  ][value - 1];
                  return mark;
                }}
              />
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Satisfaction with Current Alternatives</Typography>
            <Typography variant="body2" gutterBottom>How satisfied is your target audience with current solutions?</Typography>
            <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
            <Slider
              name="satisfactionWithAlternatives"
              value={6 - idea.satisfactionWithAlternatives} // Reverse the value
              onChange={(event, newValue) => handleSliderChange('satisfactionWithAlternatives')(event, 6 - newValue)}
              min={1}
              max={5}
              step={1}
              marks={false}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                const labels = [
                  'Very satisfied',
                  'Somewhat satisfied',
                  'Neutral',
                  'Somewhat dissatisfied',
                  'Very dissatisfied'
                ];
                return labels[5 - value]; // Reverse the label order
              }}
              sx={{ flexGrow: 1, mr: 2 }}
            />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Competitive Differentiation</Typography>
              <Typography variant="body2" gutterBottom>How differentiated is your solution from existing competitors?</Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <Slider
                  name="competitiveDifferentiation"
                  value={idea.competitiveDifferentiation}
                  onChange={handleSliderChange('competitiveDifferentiation')}
                  min={1}
                  max={5}
                  step={1}
                  marks={false}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => {
                    const labels = [
                      'Not differentiated',
                      'Slightly differentiated',
                      'Moderately differentiated',
                      'Differentiated',
                      'Highly differentiated'
                    ];
                    return labels[value - 1];
                  }}
                  sx={{ flexGrow: 1, mr: 2 }}
                />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>

            <Box sx={{ mt: 6 }}>
                <Typography variant="h6" gutterBottom>Market Size & Growth Potential</Typography>
                <Typography variant="body2" gutterBottom>How large is the market for your product, and is it growing?</Typography>
                <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                  <Slider
                    name="marketSizeAndGrowth"
                    value={idea.marketSizeAndGrowth}
                    onChange={handleSliderChange('marketSizeAndGrowth')}
                    min={1}
                    max={5}
                    step={1}
                    marks={false}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {
                      const labels = [
                        'Small, declining market',
                        'Small, stable market',
                        'Moderate, stable market',
                        'Large, growing market',
                        'Very large, rapidly growing market'
                      ];
                      return labels[value - 1];
                    }}
                    sx={{ flexGrow: 1, mr: 2 }}
                  />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
                </Box>
              </Box>
            </Box>
          );
      case 3:
        return (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Execution Ability</Typography>
            <Typography variant="body2" gutterBottom>How capable is your team of executing this idea?</Typography>
            <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
              <Slider
                name="executionAbility"
                value={idea.executionAbility}
                onChange={handleSliderChange('executionAbility')}
                min={1}
                max={5}
                step={1}
                marks={false}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  const labels = [
                    'Weak or no team',
                    'Somewhat capable',
                    'Moderately capable',
                    'Capable',
                    'Strong, experienced team'
                  ];
                  return labels[value - 1];
                }}
                sx={{ flexGrow: 1, mr: 2 }}
              />
              <Tooltip 
                title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                arrow
                placement="top"
              >
                <span>
                </span>
              </Tooltip>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Market Readiness</Typography>
              <Typography variant="body2" gutterBottom>Is the market ready for your solution?</Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <Slider
                  name="marketReadiness"
                  value={idea.marketReadiness}
                  onChange={handleSliderChange('marketReadiness')}
                  min={1}
                  max={5}
                  step={1}
                  marks={false}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => {
                    const labels = [
                      'Market not ready',
                      'Somewhat unready',
                      'Moderately ready',
                      'Ready',
                      'Market is fully ready and waiting'
                    ];
                    return labels[value - 1];
                  }}
                  sx={{ flexGrow: 1, mr: 2 }}
                />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Customer Acquisition Plan and Costs</Typography>
              <Typography variant="body2" gutterBottom>How do you plan to acquire customers, and how costly will it be?</Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <Slider
                  name="customerAcquisitionPlan"
                  value={idea.customerAcquisitionPlan}
                  onChange={handleSliderChange('customerAcquisitionPlan')}
                  min={1}
                  max={5}
                  step={1}
                  marks={false}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => {
                    const labels = [
                      'Very expensive, unclear',
                      'Somewhat expensive',
                      'Moderately affordable',
                      'Affordable',
                      'Very affordable, clear plan'
                    ];
                    return labels[value - 1];
                  }}
                  sx={{ flexGrow: 1, mr: 2 }}
                />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Revenue Model</Typography>
            <Typography variant="body2" gutterBottom>What is your primary revenue model?</Typography>
            <FormControl fullWidth margin="normal" sx={{ mb: 4 }}>
              <Select
                name="revenueModel"
                value={idea.revenueModel}
                onChange={handleChange}
                required
              >
                <MenuItem value="Subscription">Subscription</MenuItem>
                <MenuItem value="Transactional">Transactional</MenuItem>
                <MenuItem value="Advertising">Advertising</MenuItem>
                <MenuItem value="Freemium">Freemium</MenuItem>
                <MenuItem value="Marketplace">Marketplace</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Scalable and Sustainable Revenue</Typography>
              <Typography variant="body2" gutterBottom>How scalable and sustainable is your revenue model?</Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <Slider
                  name="scalableRevenue"
                  value={idea.scalableRevenue}
                  onChange={handleSliderChange('scalableRevenue')}
                  min={1}
                  max={5}
                  step={1}
                  marks={false}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => {
                    const labels = [
                      'Not scalable',
                      'Slightly scalable',
                      'Moderately scalable',
                      'Scalable',
                      'Highly scalable and sustainable'
                    ];
                    return labels[value - 1];
                  }}
                  sx={{ flexGrow: 1, mr: 2 }}
                />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h6" gutterBottom>Costs and Profit Margins</Typography>
              <Typography variant="body2" gutterBottom>How manageable are your costs, and do they allow for healthy profit margins?</Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <Slider
                  name="costsAndProfitMargins"
                  value={idea.costsAndProfitMargins}
                  onChange={handleSliderChange('costsAndProfitMargins')}
                  min={1}
                  max={5}
                  step={1}
                  marks={false}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => {
                    const labels = [
                      'High costs, low margins',
                      'Moderate costs, thin margins',
                      'Balanced costs and margins',
                      'Low costs, good margins',
                      'Very low costs, excellent margins'
                    ];
                    return labels[value - 1];
                  }}
                  sx={{ flexGrow: 1, mr: 2 }}
                />
                <Tooltip 
                  title={<Typography sx={{ fontSize: '1rem' }}>{ideallyAITooltipText}</Typography>} 
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{ textTransform: 'none', color: 'gray' }}
                      disabled
                    >
                      Validate with IdeallyAI
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 6, mt: 6, backgroundColor: 'background.paper' }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}>
            <EmojiObjectsIcon sx={{ mr: 1 }} />
            {isEditing ? 'Edit Idea' : 'New Idea'}
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <form onSubmit={handleSubmit}>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }} >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {isEditing ? 'Update' : 'Submit'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </Box>
          </form>
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
            <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default IdeaForm;