import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { getIdeaTitle } from './Dashboard';

const calculateOpportunityScore = (importance, satisfaction) => {
  const imp = parseFloat(importance);
  const sat = parseFloat(satisfaction);

  if (isNaN(imp) || isNaN(sat)) {
    return NaN; // Return NaN if inputs are not valid numbers
  }

  const rawScore = imp + (imp - sat);
  const minScore = -8;
  const maxScore = 19;
  return ((rawScore - minScore) / (maxScore - minScore)) * 9 + 1;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div style={{
        backgroundColor: '#1a0033',
        padding: '10px',
        border: '1px solid #4a0033',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
      }}>
        <p style={{ color: '#facc15', margin: '0 0 5px 0' }}>{data.ideaName}</p> {/* Add Idea Name */}
        <p style={{ color: '#e0e0ff', margin: '0' }}>Idea Score: {(parseFloat(data.score) || 0).toFixed(2)}</p>
        <p style={{ color: '#e0e0ff', margin: '0' }}>Market Need Score: {data.opportunityScore.toFixed(2)}</p>
      </div>
    );
  }
  return null;
};

const IdeaComparisonVisualizations = ({ ideas }) => {
  const chartData = ideas.map(idea => ({
    ...idea,
    ideaName: getIdeaTitle(idea), // Use the function to get the correct title
    score: parseFloat(idea.score) || 0,
    opportunityScore: calculateOpportunityScore(idea.importanceOfProblem, idea.satisfactionWithAlternatives)
  })).filter(idea => !isNaN(idea.score) && !isNaN(idea.opportunityScore));

    // Calculate dynamic axis limits
    const minScore = Math.max(0, Math.min(...chartData.map(idea => idea.score)) - 0.4);
    const maxScore = Math.min(5, Math.max(...chartData.map(idea => idea.score)) + 0.4);
    const minOpportunityScore = Math.min(...chartData.map(idea => idea.opportunityScore)) - 0.4;
    const maxOpportunityScore = Math.max(...chartData.map(idea => idea.opportunityScore)) + 0.4;

  const getColor = (score) => {
    if (score >= 4) return '#4CAF50';  // Green for high scores
    if (score >= 3) return '#FFC107';  // Yellow for medium scores
    return '#F44336';  // Red for low scores
  };

  return (
    <div style={{ width: '100%', height: 400, backgroundColor: '#1a0033' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart margin={{ top: 10, right: 10, bottom: 30, left: 30 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#4a0033" />
          <XAxis 
            type="number" 
            dataKey="score" 
            name="Idea Score" 
            domain={[minScore, maxScore]} 
            tickCount={5}
            stroke="#e0e0ff"
            tickFormatter={(tick) => tick.toFixed(1)}
          >
            <Label value="Idea Score" position="bottom" offset={10} fill="#facc15" />
          </XAxis>
          <YAxis 
            type="number" 
            dataKey="opportunityScore" 
            name="Market Need Score" 
            domain={[minOpportunityScore, maxOpportunityScore]}
            tickCount={10}
            stroke="#e0e0ff"
            tickFormatter={(tick) => tick.toFixed(1)}
          >
            <Label value="Market Need Score" angle={-90} position="insideLeft" offset={0} fill="#facc15" style={{textAnchor: 'middle'}} />
          </YAxis>
          <ZAxis type="number" range={[100, 500]} />
          <Tooltip content={<CustomTooltip />} />
          <Scatter name="Ideas" data={chartData} fill="#facc15">
            {chartData.map((entry, index) => (
              <circle key={`dot-${index}`} fill={getColor(entry.score)} r={8} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default IdeaComparisonVisualizations;