import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebase.js';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, Typography, CircularProgress, Box, ThemeProvider, createTheme,
  Dialog, DialogActions, DialogContent, TextField, DialogTitle, DialogContentText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MiniGaugeMeter from './MiniGaugeMeter';
import IdeaComparisonVisualizations from './IdeaComparisonVisualizations';
import { calculateScore } from '../utils/ScoringLogic';
import { updateDoc } from 'firebase/firestore';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#facc15',
    },
    background: {
      default: '#000000',
      paper: '#1a0033',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#e0e0ff',
        },
      },
    },
  },
});

export const getIdeaTitle = (idea) => {
  if (!idea || !idea.templateData) return idea.title || 'Untitled';
  switch (idea.templateId) {
    case 'classic':
      return idea.templateData['Product Name'] || 'Untitled';
    case 'problem':
      return idea.templateData['A Problem'] || 'Untitled';
    case 'solution':
    case 'value':
      return idea.templateData['Product/Service'] || 'Untitled';
    case 'quick note':
      return idea.title || 'Untitled';
    default:
      return 'Untitled';
  }
};

const updateIdeaNote = async (id, note) => {
  const ideaRef = doc(db, 'ideas', id);
  await updateDoc(ideaRef, { note });
};

const Dashboard = () => {
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [ideaToDelete, setIdeaToDelete] = useState(null);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState('');
  const [ideaWithNote, setIdeaWithNote] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc'); // Default to descending
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const q = query(collection(db, 'ideas'), where('userId', '==', user.uid));
        const ideaUnsubscribe = onSnapshot(q, (querySnapshot) => {
          const newIdeas = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              score: calculateScore(data) // Calculate score for each idea
            };
          });
          // Sort ideas by score in descending order
          const sortedIdeas = newIdeas.sort((a, b) => b.score - a.score);
          setIdeas(sortedIdeas);
          setLoading(false);
        });
        return () => ideaUnsubscribe();
      } else {
        setIdeas([]);
        setLoading(false);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const sortIdeas = () => {
    const sortedIdeas = [...ideas].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.score - b.score;
      } else {
        return b.score - a.score;
      }
    });
    setIdeas(sortedIdeas);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const getIdeaDescription = (idea) => {
    if (!idea || !idea.templateData) return idea.description || 'No description available.';
    switch (idea.templateId) {
      case 'classic':
        return `${idea.templateData['Product Name'] || 'Product'} helps ${idea.templateData['Target Audience'] || 'target audience'} achieve ${idea.templateData['Desired Goal'] || 'desired goal'} by ${idea.templateData['Product Use Case'] || 'product use case'}.`;
      case 'problem':
        return `A problem for ${idea.templateData['Well Defined Target Customers'] || 'target customers'} because ${idea.templateData['Specific Underlying Cause of the Problem'] || 'cause'}, which leads to ${idea.templateData['The Problem Impact'] || 'impact'}.`;
      case 'solution':
        return `By offering ${idea.templateData['Key Solution'] || 'solution'}, ${idea.templateData['Product/Service'] || 'product/service'} helps ${idea.templateData['Target Audience'] || 'target audience'} overcome ${idea.templateData['Specific Problem'] || 'problem'}.`;
      case 'value':
        return `${idea.templateData['Product/Service'] || 'Product/Service'} simplifies ${idea.templateData['Process/Task'] || 'process/task'} for ${idea.templateData['Target Audience'] || 'target audience'} by ${idea.templateData['How'] || 'how'}, leading to ${idea.templateData['Benefit'] || 'benefit'}.`;
      case 'quick note':
        return idea.description || 'No description available.';
      default:
        return 'No description available.';
    }
  };

  const deleteIdea = async (id) => {
    await deleteDoc(doc(db, 'ideas', id));
  };

  const viewIdea = (id) => {
    navigate(`/idea/${id}`);
  };

  const editIdea = (id) => {
    navigate(`/ideaform/${id}`);
  };

  const handleDeleteClick = (idea) => {
    setIdeaToDelete(idea);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (ideaToDelete) {
      await deleteIdea(ideaToDelete.id);
      setDeleteConfirmOpen(false);
      setIdeaToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setIdeaToDelete(null);
  };

  const handleNoteClick = (idea) => {
    setIdeaWithNote(idea);
    setCurrentNote(idea.note || '');
    setNoteDialogOpen(true);
  };

  const handleNoteSave = async () => {
    if (ideaWithNote) {
      await updateIdeaNote(ideaWithNote.id, currentNote);
      const updatedIdeas = ideas.map(idea => 
        idea.id === ideaWithNote.id ? { ...idea, note: currentNote } : idea
      );
      setIdeas(updatedIdeas);
      setNoteDialogOpen(false);
    }
  };

  const handleNoteCancel = () => {
    setNoteDialogOpen(false);
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 1, backgroundColor: 'background.default', minHeight: '100vh' }}>
        <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>
          <Typography variant="h4" component="h1" gutterBottom color="primary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <AutoAwesomeIcon sx={{ mr: 1 }} /> Your Ideas Dashboard
          </Typography>

          <Box sx={{ mt: 1, mb: 2, position: 'relative' }}>
            <IdeaComparisonVisualizations ideas={ideas} />
          </Box>

          <Typography variant="h5" component="h2" gutterBottom color="primary" sx={{ mt: 6, mb: 2 }}>
            Ideas List
          </Typography>

          <TableContainer component={Paper} sx={{ backgroundColor: 'background.paper', mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell onClick={sortIdeas} sx={{ cursor: 'pointer' }}>
                    Score {sortOrder === 'asc' ? '↑' : '↓'}
                  </TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ideas.map((idea) => (
                  <TableRow key={idea.id}>
                    <TableCell sx={{ fontSize: 'inherit' }}>{getIdeaTitle(idea)}</TableCell>
                    <TableCell sx={{ fontSize: 'inherit' }}>{getIdeaDescription(idea)}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <MiniGaugeMeter score={idea.score} />
                        <Typography sx={{ ml: 1 }}>{idea.score ? idea.score.toFixed(2) : 'N/A'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ fontSize: 'inherit' }}>
                      <Typography
                        sx={{ color: idea.note ? 'inherit' : 'gray', cursor: 'pointer' }}
                        onClick={() => handleNoteClick(idea)}
                      >
                        {idea.note || 'Add notes'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <DeleteIcon 
                          sx={{ cursor: 'pointer', mx: 1 }} 
                          onClick={() => handleDeleteClick(idea)} 
                          color="primary" 
                        />
                        <EditIcon 
                          sx={{ cursor: 'pointer', mx: 1 }} 
                          onClick={() => editIdea(idea.id)} 
                          color="primary" 
                        />
                        <VisibilityIcon 
                          sx={{ cursor: 'pointer', mx: 1 }} 
                          onClick={() => viewIdea(idea.id)} 
                          color="primary" 
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
          </Table>
        </TableContainer>

        <Dialog
            open={noteDialogOpen}
            onClose={handleNoteCancel}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>{ideaWithNote ? `${getIdeaTitle(ideaWithNote)} notes` : 'Notes'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Notes"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={currentNote}
                onChange={(e) => setCurrentNote(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNoteCancel} color="primary">Cancel</Button>
              <Button onClick={handleNoteSave} color="primary">Save</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={deleteConfirmOpen}
            onClose={handleDeleteCancel}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete "{ideaToDelete ? getIdeaTitle(ideaToDelete) : 'Untitled'}"?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCancel} color="primary">No, Keep</Button>
              <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                Yes, Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;