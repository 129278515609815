import React from 'react';
import { Box } from '@mui/material';

function GaugeMeter({ score }) {
  const percentage = (score / 5) * 100;

  return (
    <Box
      sx={{
        width: '200px',
        height: '100px',
        background: 'linear-gradient(to right, red, yellow, green)',
        borderRadius: '100px 100px 0 0',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: '4px',
          height: '90px',
          background: 'black',
          transform: `rotate(${percentage * 1.8 - 90}deg)`,
          transformOrigin: 'bottom',
        }}
      />
    </Box>
  );
}

export default GaugeMeter;