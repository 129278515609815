import React from 'react';
import { Box } from '@mui/material';

const MiniGaugeMeter = ({ score }) => {
  const percentage = ((score || 0) / 5) * 100;
  const color = `hsl(${percentage * 1.2}, 100%, 50%)`;

  return (
    <Box
      sx={{
        width: '30px',
        height: '15px',
        background: 'linear-gradient(to right, red, yellow, green)',
        borderRadius: '15px 15px 0 0',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: '2px',
          height: '13px',
          background: 'black',
          transform: `rotate(${percentage * 1.8 - 90}deg)`,
          transformOrigin: 'bottom',
        }}
      />
    </Box>
  );
};

export default MiniGaugeMeter;