import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Typography, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import { db } from '../firebase'; // Adjust the path according to your project structure

function SharedIdea() {
  const { id } = useParams();
  const [idea, setIdea] = useState(null);

  useEffect(() => {
    const fetchIdea = async () => {
      try {
        const docRef = doc(db, 'ideas', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIdea({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchIdea();
  }, [id]);

  if (!idea) return <Typography>Loading...</Typography>;

  // Ensure idea.description is defined before calling substring
  const description = idea.description ? idea.description.substring(0, 200) : "No description available";

  return (
    <>
      <Helmet>
        <title>{`${idea.title} - Ideally`}</title>
        <meta property="og:title" content={`${idea.title} - Ideally`} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={idea.imageUrl || "https://www.ideally.pro/og-image.jpg"} />
        <meta property="og:url" content={`https://www.ideally.pro/shared/${id}`} />
        <meta name="twitter:title" content={`${idea.title} - Ideally`} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={idea.imageUrl || "https://www.ideally.pro/og-image.jpg"} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Paper style={{ padding: '20px', margin: '20px' }}>
        <Typography variant="h4">{idea.title}</Typography>
        <Typography variant="h6">Score: {idea.score}</Typography>
        <Typography>{idea.description || "No description available"}</Typography>
        {/* Add more details as needed */}
      </Paper>
    </>
  );
}

export default SharedIdea;