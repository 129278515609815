import React from 'react';
import { Button, Typography, Container, Box, Link as MuiLink, ThemeProvider, createTheme, Paper } from '@mui/material';
import { signInWithGoogle } from '../firebase.js';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#facc15',
    },
    background: {
      default: '#2c003e', // Set the background to purple
      paper: '#2c003e',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const Login = () => {
  const navigate = useNavigate();

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      navigate('/');
    } catch (error) {
      console.error("Error signing in with Google", error);
      alert("Failed to sign in with Google. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: 'background.default' 
      }}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 4, width: '100%', backgroundColor: 'background.paper' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}>
              <Box display="flex" alignItems="center" mb={4}>
                <AutoAwesomeIcon sx={{ fontSize: 40, color: 'primary.main', mr: 1 }} />
                <Typography component="h1" variant="h3" color="primary">
                  Ideally
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Login to Ideally and start evaluating your ideas
              </Typography>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={handleSignInWithGoogle}
                startIcon={<GoogleIcon sx={{ color: '#1a0033' }} />} // Set icon color to purple
                sx={{
                  mt: 2,
                  backgroundColor: 'white',
                  color: '#1a0033', // Set text color to purple
                  border: '1px solid #facc15', // Set border to yellow
                  '&:hover': {
                    backgroundColor: '#f1f1f1',
                  },
                }}
              >
                CONNECT WITH GOOGLE
              </Button>
              <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                Created with ❤️ by <MuiLink href="https://il.linkedin.com/in/revivolior" target="_blank" rel="noopener noreferrer" sx={{ fontWeight: 'bold', textDecoration: 'none', color: 'primary.main', '&:hover': { textDecoration: 'underline' } }}>Lior Revivo</MuiLink>
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Login;