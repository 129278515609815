import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDAg7XfxMW30uuyYEve1fG0IVqyxF1RRy8",
  authDomain: "opportunity-scorer.firebaseapp.com",
  projectId: "opportunity-scorer",
  storageBucket: "opportunity-scorer.appspot.com",
  messagingSenderId: "687480698679",
  appId: "1:687480698679:web:eb99f76b7ba703b0d44cb2"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result.user;
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw error;
  }
};

export default app;