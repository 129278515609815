export const calculateScore = (idea) => {
    const case0Score = 0; // This case doesn't contribute to the score
    const case1Score = 0.25 * ((idea.importanceOfProblem + idea.frequencyOfProblem + idea.targetAudienceDefinition) / 3);
    const case2Score = 0.25 * ((idea.satisfactionWithAlternatives + idea.competitiveDifferentiation + idea.marketSizeAndGrowth) / 3);
    const case3Score = 0.25 * ((idea.executionAbility + idea.customerAcquisitionPlan + idea.marketReadiness) / 3);
    const case4Score = 0.25 * ((getRevenueModelScore(idea.revenueModel) + idea.scalableRevenue + idea.costsAndProfitMargins) / 3);
  
    const totalScore = case0Score + case1Score + case2Score + case3Score + case4Score;
    return totalScore;
  };
  
  // Helper function to convert revenueModel to a numeric score
  const getRevenueModelScore = (revenueModel) => {
    const scores = {
      'Subscription': 5,
      'Transactional': 4,
      'Advertising': 3,
      'Freemium': 4,
      'Marketplace': 5,
      'Other': 3,
    };
    return scores[revenueModel] || 3; // Default to 3 if not found
  };
  
  export const getScoreColor = (score) => {
    if (score < 1.7) return 'red';
    if (score < 3.4) return 'yellow';
    return 'green';
  };
  
  export const getScoreExplanation = (score) => {
    if (score >= 4.5) return 'Excellent potential';
    if (score >= 3.5) return 'Good potential, some areas need improvement';
    if (score >= 2.5) return 'Moderate potential, requires significant validation';
    return 'Low potential, the idea may need rethinking or refinement';
  };