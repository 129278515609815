import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, CircularProgress, ThemeProvider, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Dashboard from './components/Dashboard.js';
import IdeaForm from './components/IdeaForm.js';
import IdeaSummary from './components/IdeaSummary.js';
import LandingPage from './components/LandingPage.js';
import Login from './components/login.js';
import SharedIdea from './components/ShareIdea.js';
import { auth, db } from './firebase.js';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#facc15',
    },
    background: {
      default: '#000000',
      paper: '#1a0033',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quickCaptureOpen, setQuickCaptureOpen] = useState(false);
  const [quickIdeaTitle, setQuickIdeaTitle] = useState('');
  const [quickIdeaDescription, setQuickIdeaDescription] = useState('');
  const [quickCaptureDetailsOpen, setQuickCaptureDetailsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('Auth state changed:', user ? 'User logged in' : 'No user');
      setUser(user);
      setLoading(false);

      if (user) {
        if (location.pathname === '/' || location.pathname === '/login') {
          const ideasRef = collection(db, 'ideas');
          const q = query(ideasRef, where('userId', '==', user.uid));
          try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
              console.log('No ideas found, navigating to /ideaform');
              navigate('/ideaform');
            } else {
              console.log('Ideas found, navigating to /dashboard');
              navigate('/dashboard');
            }
          } catch (error) {
            console.error('Error fetching ideas:', error);
          }
        }
      } else if (location.pathname !== '/' && location.pathname !== '/login') {
        console.log('No user, navigating to /');
        navigate('/');
      }
    });
    return () => unsubscribe();
  }, [navigate, location]);

  const handleLogout = () => {
    auth.signOut().then(() => {
      console.log('User signed out, navigating to /');
      navigate('/');
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  const handleQuickCaptureSubmit = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const ideaData = {
      title: quickIdeaTitle,
      description: quickIdeaDescription,
      userId: user.uid,
      createdAt: new Date(),
      updatedAt: new Date(),
      templateId: 'quick note',
    };

    try {
      await addDoc(collection(db, 'ideas'), ideaData);
      setQuickCaptureDetailsOpen(false);
      setQuickIdeaTitle('');
      setQuickIdeaDescription('');
      navigate('/dashboard');
    } catch (error) {
      console.error("Error submitting quick idea: ", error);
    }
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        {user && (
          <AppBar position="static" sx={{ backgroundColor: 'background.paper' }}>
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', color: 'primary.main' }}>
                <AutoAwesomeIcon sx={{ mr: 1 }} />
                Ideally
              </Typography>
              <Button color="primary" component={Link} to="/dashboard">Dashboard</Button>
              <Button color="primary" onClick={() => setQuickCaptureOpen(true)}>New Idea</Button>
              <Button color="primary" onClick={handleLogout}>Logout</Button>
            </Toolbar>
          </AppBar>
        )}

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="/ideaform" element={user ? <IdeaForm /> : <Navigate to="/login" />} />
          <Route path="/ideaform/:id" element={user ? <IdeaForm /> : <Navigate to="/login" />} />
          <Route path="/idea/:id" element={user ? <IdeaSummary /> : <Navigate to="/login" />} />
          <Route path="/shared/:id" element={<SharedIdea />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        <Dialog open={quickCaptureOpen} onClose={() => setQuickCaptureOpen(false)}>
          <DialogTitle>New Idea. Great!</DialogTitle>
          <DialogContent>
          <Typography>What are you looking for?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 1 }}>
          <Button onClick={() => { navigate('/ideaform'); setQuickCaptureOpen(false); }}>Deeper analysis of an idea</Button>
          <Button onClick={() => { setQuickCaptureDetailsOpen(true); setQuickCaptureOpen(false); }}>Quickly capture an idea on the go and edit later</Button>
          </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setQuickCaptureOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={quickCaptureDetailsOpen} onClose={() => setQuickCaptureDetailsOpen(false)}>
          <DialogTitle>Quick Capture</DialogTitle>
          <DialogContent>
            <TextField
              label="Idea Name"
              fullWidth
              value={quickIdeaTitle}
              onChange={(e) => setQuickIdeaTitle(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Idea Description"
              fullWidth
              multiline
              rows={4}
              value={quickIdeaDescription}
              onChange={(e) => setQuickIdeaDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setQuickCaptureDetailsOpen(false)}>Cancel</Button>
            <Button onClick={handleQuickCaptureSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </>
    </ThemeProvider>
  );
}

export default App;